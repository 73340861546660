<template>
    <div :class="className" v-on:focus.prevent="focus">
        <component :is="`field-${elementType}`" :type="type" :inputmode="inputmode" :rows="rows" :name="name" v-model="value" :value="inputValue" :input-value="value" :placeholder="placeholder" :id="id" :options="options" :multiple="multiple" :simple="simple" :label="label" v-bind="attrs" ref="field"></component>
        <label v-if="this.$slots.default" class="form--field--label" :for="$attrs.id">
            <slot></slot>
            <span v-if="$attrs.required" class="form--field--label--required">&nbsp;*</span>
        </label>
    </div>
</template>

<script>
import FieldText from './field/text'
import FieldTextarea from './field/textarea'
import FieldRichText from './field/rich-text'
import FieldRadio from './field/radio'
import FieldCheckbox from './field/checkbox'
import FieldSelect from './field/select'
import FieldDate from './field/date'
import FieldFiles from './field/files'
import FieldPostcode from './field/postcode'
import FieldAutocomplete from './field/autocomplete'

export default {
    components: { FieldText, FieldTextarea, FieldRichText, FieldRadio, FieldCheckbox, FieldSelect, FieldDate, FieldFiles, FieldPostcode, FieldAutocomplete },
    props: {
        type: String,
        inputmode: {
            type: String,
            required: false
        },
        rows: {
            type: [String, Number],
            required: false
        },
        name: String,
        inputValue: {
            type: [String, Number],
            required: false
        },
        modelValue: [String, Number, Array, Boolean],
        placeholder: {
            type: String,
            required: false
        },
        id: String,
        options: {
            type: Object,
            required: false
        },
        multiple: {
            type: Boolean,
            required: false
        },
        simple: {
            type: Boolean,
            required: false
        },
        error: {
            type: Boolean,
            required: false
        },
        props: {
            type: Object,
            required: false
        },
        disabled: {
            type:Boolean,
            required:false
        }
    },
    data () {
        return {
            value: ''
        }
    },
    computed: {
        attrs () {
            const attrs = Object.assign({}, this.$attrs)

            Object.keys(attrs).forEach(key => {
                if (/^on[A-Z]/.test(key)) {
                    delete attrs[key]
                }
            })

            return attrs
        },
        elementType () {
            switch (this.type) {
                case 'textarea':
                    return 'textarea'

                case 'radio':
                    return 'radio'

                case 'checkbox':
                    return 'checkbox'

                case 'select':
                    return 'select'

                case 'date':
                    return 'date'

                case 'rich-text':
                    return 'rich-text'

                case 'files':
                    return 'files'

                case 'postcode':
                    return 'postcode'

                case 'autocomplete':
                    return 'autocomplete'

                default:
                    return 'text'
            }
        },
        className () {
            let className = `form--field form--field__${this.elementType}`

            if (this.value) {
                className += ' form--field__filled'
            }

            if (this.error) {
                className += ' form--field__error'
            }

            if (!this.label) {
                className += ' form--field__no-label'
            }

            if (this.disabled) {
                className += ' form--field__disabled'
            }

            return className
        },
        label () {
            if (typeof this.$slots.default === 'function') {
                return this.$slots.default().map(vnode => vnode.children).join('') || undefined
            }

            return undefined
        }
    },
    watch: {
        modelValue (value) {
            if (this.value !== this.sanitizeValue(value)) {
                this.value = this.sanitizeValue(value)
            }
        },
        value (value) {
            if (this.modelValue !== value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        sanitizeValue (value) {
            if (typeof value === 'boolean') {
                return String(Number(value))
            }

            if (typeof value === 'number') {
                return String(value)
            }

            return value
        }
    },
    created () {
        if (typeof this.value !== 'undefined') this.value = this.sanitizeValue(this.modelValue)
    }
}
</script>
