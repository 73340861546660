import Store from "./store";

require('./bootstrap');
import { createApp } from 'vue';
import { i18nVue } from 'laravel-vue-i18n';
import * as Sentry from '@sentry/vue'
import Home from './views/home.vue';
import MainHeader from './views/layout/main-header.vue';
import router from './router.js'
import SideMenu from "./views/layout/menu/index.vue";
import Permissions from "./src/permissions";
import datetime from "./src/datetime";

window.app = createApp({
    components: { MainHeader, Home, SideMenu },
    mixins:[datetime],
    data () {
        return {
            loading: true,
            user: null,
            email: null,
        }
    },
    computed: {
    },
    watch: {
/*        $route (to, from){
            console.log(to)
            console.log(from)
            if(to.fullPath !== from.fullPath ) {
                console.log('inside $rout');
                this.$forceUpdate();
            }
        }*/
    },
    methods: {
    },
    created () {
    },
    mounted() {
    },
})
Sentry.init({
    app,
    dsn: window.sentryDsn,
    environment: window.env,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
})
app.mixin(Permissions)
app.use(router)
app.mixin(Permissions)
app.use(router)
let match = document.cookie.match(new RegExp('(^| )' + '_lang' + '=([^;]+)'));
let locale
if(match?.length > 1) {
    locale = match[2];
} else {
    locale = 'en'
}
app.use(i18nVue, {
    lang: locale,
    resolve: lang => import(`../lang/${lang}.json`),
})

// app.use(i18nVue, { resolve: lang => import(`../lang/${/^php_/.test(lang) ? lang : `php_${lang}`}.json`) })
app.mount('.page-wrapper')
