<template>
    <i class="icon icon__notification main-body--breadcrumb-header--breadcrumbs--notifications"></i>
</template>
<script>
import FormField from '../../../components/form/field.vue'
import Form from "../../../src/form";

export default {
    props: {
    },
    components: { FormField },
    inheritAttrs: false,
    data () {
        return {
            user: [],
            alertCount:null,

        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
        checkEnquiries(){

        },
        showNotifications(){

        }
    },
    created () {
    },
    mounted () {
        if (!localStorage.getItem('user')) {
            location.href = '/login'
        } else {
            this.user = JSON.parse(localStorage.getItem('user'));

        }
    }
}
</script>
