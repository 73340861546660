<template>
<!--    <div class="main-content">
        <h1>Hello Vue!</h1>
        <p>This is me trying to see if I can get this new style of front end working!</p>
    </div>-->
</template>

<script>
import FormField from "../components/form/field.vue";
import Form from "../src/form";

export default {
    components: { FormField },
    data () {
        return {
            form: new Form(this),
            errors: {},
            loading: false,
        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    created () {
    },
    mounted() {
        if(localStorage.user === undefined) {
            location.href = '/login'
        } else {
            this.user = JSON.parse(localStorage.getItem('user'));
            if(this.user.role.name !== 'Super User'){
                location.href = '/activity-provider/dashboard';
            } else {
                location.href = '/dashboard';
            }
        }
    }
}
</script>
