<template>
    <div v-if="multiple" class="form--field--input form--field--input__multiple" :class="{ 'form--field--input__open': showOptions }" @click="toggleOptions">
        <span v-if="placeholder && value.length === 0" class="form--field--input--placeholder">{{ placeholder }}</span>
        <span v-for="option in selectedOption" :key="option.value" class="form--field--input--option" @click.stop="change(option.value)"><input type="hidden" :name="multipleName" :value="option.value">{{ option.label }}</span>
    </div>
    <input v-else class="form--field--input" :class="{ 'form--field--input__open': showOptions }" :name="name" :value="selectedLabel" :placeholder="placeholder" :id="id" v-bind="props" readonly @click="toggleOptions">
    <div class="form--field--options" ref="options">
        <div v-if="Object.keys(options).length === 0" class="form--field--options--option form--field--options--option__empty">No options</div>
        <div v-if="Object.keys(options).length !== 0" @click="change(null)"
             class="form--field--options--option form--field--options--option__empty">Clear option
        </div>
        <div v-for="option in options" :key="option.value" class="form--field--options--option" :class="{ 'form--field--options--option__selected': multiple ? value.indexOf(option.value) > -1 : option.value === value }" @click="change(option.value)" ref="option">{{ option.label }}</div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        name: String,
        modelValue: [String, Number, Array],
        label: String,
        placeholder: {
            type: String,
            required: false
        },
        id: String,
        options: Object,
        multiple: Boolean,
        props: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            value: '',
            showOptions: false
        }
    },
    computed: {
        multipleName () {
            return this.name.substr(-1) === ']' ? this.name : `this.name[]`
        },
        selectedOption () {
            if (this.multiple) {
                return this.options.filter(option => this.value.indexOf(option.value) > -1)
            }

            return this.options.find(option => String(option.value) === String(this.value))
        },
        selectedLabel () {
            if (this.multiple) {
                if (this.selectedOption) {
                    return this.selectedOption.map(option => option.label)
                }

                return []
            }

            if (this.selectedOption) {
                return this.selectedOption.label
            }

            return ''
        }
    },
    watch: {
        modelValue (value) {
            this.value = value
        },
        value (value) {
            this.$emit('update:modelValue', value)
        }
    },
    methods: {
        toggleOptions (force) {
            if (this.$attrs.disabled) return

            if (typeof force === 'boolean') {
                this.showOptions = force
            } else {
                this.showOptions = !this.showOptions
            }
        },
        change (value) {
            // console.log(value)
            if (this.multiple) {
                const index = this.value.indexOf(value)

                if (index === -1) {
                    this.value.push(value)
                } else {
                    this.value.splice(index, 1)
                }
            } else {
                this.value = value
            }

            this.toggleOptions(false)
        },
        cancel (e) {
            if (!this.showOptions) {
                return
            }

            if (e instanceof MouseEvent) {
                let elem = e.target

                while (elem instanceof Element) {
                    if (elem === this.$refs.options.parentElement) {
                        return
                    }

                    elem = elem.parentElement
                }
            }

            this.toggleOptions(false)
        },
        keydown (e) {
            if (!this.showOptions) {
                return
            }

            // console.log(e)

            if (e.key === 'ArrowDown') {
                e.preventDefault()
                this.$refs.options.scrollBy({ top: 46 })
            } else if (e.key === 'ArrowUp') {
                e.preventDefault()
                this.$refs.options.scrollBy({ top: -46 })
            } else if (/[a-z]/i.test(e.key) && this.$refs.option.length > 1) {
                e.preventDefault()
                const key = e.key.toLowerCase()
                const match = this.$refs.option.find(option => option.innerText.substring(0, 1).toLowerCase() === key)
                if (match) {
                    // console.log(match.getBoundingClientRect().top - match.offsetParent.getBoundingClientRect().top - match.offsetParent.scrollTop)
                    this.$refs.options.scrollTo({ top: match.getBoundingClientRect().top - match.offsetParent.getBoundingClientRect().top + match.offsetParent.scrollTop })
                    // match.scrollIntoView()
                }
            }
        }
    },
    created () {
        if (typeof this.value !== 'undefined') {
            if (this.multiple) {
                this.value = Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue]
            } else {
                this.value = this.modelValue
            }
        }
    },
    mounted () {
        window.addEventListener('click', this.cancel)
        window.addEventListener('keydown', this.keydown)
    },
    unmounted () {
        window.removeEventListener('click', this.cancel)
        window.removeEventListener('keydown', this.keydown)
    }
}
</script>
