<template>
    <input class="form--field--input" :class="{ 'form--field--input__open': showDatepicker }" :name="name" :value="value ? value.toFormat('D') : ''" :placeholder="placeholder" :id="id" readonly @click="toggleDatepicker">

    <div class="form--field--datepicker" ref="datepicker">
        <nav class="form--field--datepicker--nav">
            <a class="form--field--datepicker--nav--link" href="#" @click.prevent="previousMonth"><i class="icon__arrow-left"></i></a>

            <div class="form--field--datepicker--nav--select form--field--datepicker--nav--select__month form--field form--field__select">
                <field-select class="form--field--datepicker--nav--month" :name="`${name}__month`" :options="monthOptions" v-model="month"></field-select>
            </div>

            <div class="form--field--datepicker--nav--select form--field--datepicker--nav--select__year form--field form--field__select">
                <field-select class="form--field--datepicker--nav--year" :name="`${name}__year`" :options="yearOptions" v-model="year"></field-select>
            </div>

            <a class="form--field--datepicker--nav--link" href="#" @click.prevent="nextMonth"><i class="icon__arrow-right"></i></a>
        </nav>

        <nav class="form--field--datepicker--dates">
            <a v-for="day in daysInMonth" :key="day" href="#" class="form--field--datepicker--dates--date" :class="dayClass(day)" @click.prevent="change(this.currentMonth.set({ day }))">{{ day }}</a>
        </nav>
    </div>
</template>

<script>
import { DateTime } from 'luxon'
import FieldSelect from './select'

export default {
    inheritAttrs: false,
    components: { FieldSelect },
    props: {
        name: String,
        modelValue: [String, Number, Array],
        label: String,
        placeholder: {
            type: String,
            required: false
        },
        id: String,
        min: {
            type: [DateTime, String],
            required: false
        },
        max: {
            type: [DateTime, String],
            required: false
        }
    },
    data () {
        return {
            value: null,
            showDatepicker: false,
            currentMonth: null,
            today: DateTime.now().startOf('day'),
            month: null,
            year: null,
            monthOptions: [
                { value: 1, label: 'Jan' },
                { value: 2, label: 'Feb' },
                { value: 3, label: 'Mar' },
                { value: 4, label: 'Apr' },
                { value: 5, label: 'May' },
                { value: 6, label: 'Jun' },
                { value: 7, label: 'Jul' },
                { value: 8, label: 'Aug' },
                { value: 9, label: 'Sep' },
                { value: 10, label: 'Oct' },
                { value: 11, label: 'Nov' },
                { value: 12, label: 'Dec' }
            ],
            yearOptions: [...Array(110).keys()].map(i => {
                const date = this.$root.dateTime().year + 1 - i
                return { value: date, label: date }
            })
        }
    },
    computed: {
        daysInMonth () {
            return this.currentMonth.daysInMonth
        },
        firstDayIndex () {
            return this.currentMonth.set({ day: 1 }).weekday - 1
        },
        minValue () {
            if (this.min == null || this.min === '') {
                return null
            }

            if (typeof this.min === 'string') {
                return DateTime.fromFormat(this.min, 'dd/MM/yyyy')
            }

            return this.min
        },
        maxValue () {
            if (this.max == null || this.max === '') {
                return null
            }

            if (typeof this.max === 'string') {
                return DateTime.fromFormat(this.max, 'dd/MM/yyyy')
            }

            return this.max
        }
    },
    watch: {
        modelValue (value) {
            if (value) {
                this.value = this.$root.dateTime(value)
                this.currentMonth = this.value.set({ day: 1 })
            } else {
                this.value = null
                this.currentMonth = this.$root.dateTime().set({ day: 1 })
            }
        },
        value (value) {
            if(value !== null ) {
                this.$emit('update:modelValue', typeof value === 'object' ? value.toFormat('D') : value)
            }
        },
        currentMonth (month) {
            this.month = month.month
            this.year = month.year
        },
        month (month) {
            this.currentMonth = this.currentMonth.set({ month })
        },
        year (year) {
            this.currentMonth = this.currentMonth.set({ year })
        }
    },
    methods: {
        toggleDatepicker (force) {
            if (typeof force === 'boolean') {
                this.showDatepicker = force
            } else {
                this.showDatepicker = !this.showDatepicker
            }

            if (this.showDatepicker && this.value) {
                this.currentMonth = this.value.set({ day: 1 })
            }
        },
        previousMonth () {
            this.currentMonth = this.currentMonth.minus({ months: 1 })
        },
        nextMonth () {
            this.currentMonth = this.currentMonth.plus({ months: 1 })
        },
        dayIsDisabled (day) {
            if (!(day instanceof DateTime)) {
                day = this.currentMonth.set({ day })
            }

            if (this.minValue !== null && day < this.minValue) {
                return true
            }

            if (this.maxValue !== null && day > this.maxValue) {
                return true
            }

            return false
        },
        dayClass (day) {
            const dayObject = this.currentMonth.set({ day })
            let className = ''

            if (day === 1) {
                className += ` form--field--datepicker--dates--date__offset-${this.firstDayIndex}`
            }

            if (this.today.hasSame(dayObject, 'day')) {
                className += ' form--field--datepicker--dates--date__today'
            }

            if (this.value && this.value.hasSame(dayObject, 'day')) {
                className += ' form--field--datepicker--dates--date__selected'
            }

            if (this.dayIsDisabled(day)) {
                className += ' form--field--datepicker--dates--date__disabled'
            }

            return className.trim()
        },
        change (value) {
            if (this.dayIsDisabled(value)) {
                return
            }

            this.value = value
            this.toggleDatepicker(false)
        },
        cancel (e) {
            if (!this.showDatepicker) {
                return
            }

            if (e instanceof MouseEvent) {
                let elem = e.target

                while (elem instanceof Element) {
                    if (elem === this.$refs.datepicker.parentElement) {
                        return
                    }

                    elem = elem.parentElement
                }
            }

            this.toggleDatepicker(false)
        }
    },
    created () {
        if (typeof this.value !== 'undefined') this.value = this.modelValue

        if (this.value) {
            this.value = this.$root.dateTime(this.value)
            this.currentMonth = this.value.set({ day: 1 })
        } else {
            this.currentMonth = this.$root.dateTime()
        }
    },
    mounted () {
        window.addEventListener('click', this.cancel)
    },
    unmounted () {
        window.removeEventListener('click', this.cancel)
    }
}
</script>
