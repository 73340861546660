<template>
    <div class="mention-list">
        <template v-if="items.length">
            <a v-for="item in items" :key="item.id" href="#" class="mention-list--item" :class="{ 'mention-list--item__active': item.id === selectedItem }" @click.prevent="selectItem(item.id)">{{ item.label }}</a>
        </template>
        <span v-else class="mention-list--item mention-list--item__empty">No result</span>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        items: {
            type: Array,
            required: true,
        },
        command: {
            type: Function,
            required: true,
        },
    },
    data () {
        return {
            selectedItem: null,
        }
    },
    watch: {
        items () {
            this.selectedItem = null
        },
    },
    methods: {
        onKeyDown (event) {
            if (event.key === 'ArrowUp') {
                this.upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                this.downHandler()
                return true
            }

            if (event.key === 'Enter') {
                this.enterHandler()
                return true
            }

            return false
        },
        upHandler () {
            this.selectedItem = ((this.selectedItem + this.items.length) - 1) % this.items.length
        },
        downHandler () {
            this.selectedItem = (this.selectedItem + 1) % this.items.length
        },
        enterHandler () {
            this.selectItem(this.selectedItem)
        },
        selectItem (id) {
            const item = this.items.find(item => item.id === id)

            if (item) {
                this.command(item)
            }
        }
    }
}
</script>
