
export default class Form {
    constructor (context) {
        if (!document.querySelector('script[src*=recaptcha]')) {
            const script = document.createElement('script')
            script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaPublicKey}`
            script.async = true
            document.head.append(script)
        }

        this.context = context
    }

    static create (context) {
        return new this(context)
    }

    static flatten (data) {
        const flattenedData = {}

        Object.keys(data).forEach(key => {
            if (data[key] instanceof Object && !(data[key] instanceof File)) {
                const flattened = this.flatten(data[key])

                Object.keys(flattened).forEach(flattenedKey => {
                    flattenedData[`${key}.${flattenedKey}`] = flattened[flattenedKey]
                })
            } else if (typeof data[key] === 'boolean') {
                flattenedData[key] = +data[key]
            } else if (typeof data[key] === 'undefined' || data[key] === null) {
                flattenedData[key] = ''
            } else {
                flattenedData[key] = data[key]
            }
        })

        return flattenedData
    }

    get (url, data) {
        return this.send('GET', url, data)
    }

    post (url, data) {
        return this.send('POST', url, data)
    }

    put (url, data) {
        data._method = 'PUT'
        return this.post(url, data)
    }

    delete (url, data) {
        // data._method = 'DELETE'
        return this.send('DELETE', url, data)
    }

    async send (method, url, data) {
        if (data == null) {
            data = {}
        } else if (data instanceof FormData) {
            data = Object.fromEntries(data)
        }

        data = this.constructor.flatten(data)

        Object.keys(data).forEach(key => {
            if (key.indexOf('.') > -1) {
                data[key.replace(/\./g, '][').replace(/(^[^[\]]+)]/, '$1') + ']'] = data[key]
                delete data[key]
            }
        })

        const formData = new FormData()

        Object.keys(data).forEach(field => formData.append(field, data[field]))
        // setTimeout(() => {
        //     formData.set('_recaptcha', grecaptcha.execute(recaptchaPublicKey, { action: 'submit' }))
        // }, 500)

        const serverResponse = await fetch(url, {
            method,
            headers: !localStorage.getItem('user') ? {
                Accept: 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
            } : {
                Accept: 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                Authorization: 'Bearer ' + localStorage.accessToken
            },
            withCredentials: true,
            body: method === 'GET' ? null : formData
        })

        const response = {
            success: /^2[0-9]{2}/.test(String(serverResponse.status))}

        if (response.success) {
            if (serverResponse.headers.get('Content-Type') === 'application/json') {
                response.data = await serverResponse.json()
            } else {
                response.data = await serverResponse.text()
            }
        } else {
            if (serverResponse.status === 419 || serverResponse.status === 401) {
                response.errors = { '*': 'Your session has expired, please reload the page and try again.' }
                localStorage.removeItem('user')
                location.href = '/login'
            } else if (serverResponse.status === 403) {
                response.errors = { '*': 'You are not authorised to access this page. Please contact us if you believe this to be in error.' }
            } else if (serverResponse.status === 422) {
                response.errors = (await serverResponse.json()).errors
            } else {
                response.errors = { '*': 'Your request was unable to be processed, please try again later.' }
            }

            this.context.errors = response.errors
        }

        return response
    }
}
