<template>
    <input class="form--field--input" :class="{ 'form--field--input__open': showOptions }" :name="name" v-model="query" :placeholder="placeholder" :id="id" v-bind="props" autocomplete="off" @click="toggleOptions" @focus="toggleOptions(true)">
    <div class="form--field--options" ref="options">
        <div  v-if="loading === true"><img class="modal--spinner" src="/images/postcode-loader.svg" alt="loading..." ></div>
        <div v-if="Object.keys(options).length === 0" class="form--field--options--option form--field--options--option__empty">Enter a valid postcode above</div>
        <div v-for="option in options" :key="option.value" class="form--field--options--option" :class="{ 'form--field--options--option__selected': option.value === value }" @click="change(option.value)" >{{ option.label }}</div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        name: String,
        modelValue: [String, Number, Array],
        label: String,
        placeholder: {
            type: String,
            required: false
        },
        id: String,
        props: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            value: '',
            query: '',
            showOptions: false,
            defaultOption: { value: '*', label: 'Enter manually' },
            options: [],
            loading: false,
        }
    },
    computed: {
        selectedOption () {
            return this.options.find(option => String(option.value) === String(this.value))
        },
        selectedLabel () {
            if (this.selectedOption) {
                return this.selectedOption.label
            }
            return ''
        }
    },
    watch: {
        query (query) {
            setTimeout(() => {
                this.loading = true;
                if (this.query !== query) {
                    return
                }

                query = query.trim()

                if (query.length > 4) {
                    fetch(`/portal/address?postcode=${query}`, { headers: { Accept: 'application/json' } })
                        .then(response => response.json())
                        .then(results => { this.options = [...results, this.defaultOption]; this.loading = false;})
                }
            }, 400)
        },
        modelValue (value) {
            this.value = value
        },
        value (value) {
            this.$emit('update:modelValue', value)
        }
    },
    methods: {
        toggleOptions (force) {
            if (this.$attrs.disabled) return

            if (force instanceof Event) {
                this.showOptions = force.currentTarget === document.activeElement
            } else if (typeof force === 'boolean') {
                this.showOptions = force
            } else {
                this.showOptions = !this.showOptions
            }
        },
        change (value) {
            this.value = value
            this.toggleOptions(false)
        },
        cancel (e) {
            if (!this.showOptions) {
                return
            }

            if (e instanceof MouseEvent) {
                let elem = e.target

                while (elem instanceof Element) {
                    if (elem === this.$refs.options.parentElement) {
                        return
                    }

                    elem = elem.parentElement
                }
            }

            this.toggleOptions(false)
        }
    },
    created () {
        if (typeof this.value !== 'undefined') this.value = this.modelValue
        this.options.push(this.defaultOption)
    },
    mounted () {
        window.addEventListener('click', this.cancel)
    },
    unmounted () {
        window.removeEventListener('click', this.cancel)
    }
}
</script>
