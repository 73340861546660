import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'
import MentionList from './mention-list'

export default {
    items: async (query) => {
        if (typeof query === 'object') {
            query = query.query
        }

        return (await (await fetch(`${registeredRoutes['api.backend.users.index']}?mention=${query}`)).json()).analysts.map(analyst => ({
            id: analyst.id,
            label: analyst.name
        }))
    },
    render: () => {
        let component
        let popup

        return {
            onStart: props => {
                component = new VueRenderer(MentionList, {
                    // using vue 2:
                    // parent: this,
                    // propsData: props,
                    // using vue 3:
                    props,
                    editor: props.editor,
                })

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.querySelector('.page-wrapper'),
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                })
            },
            onUpdate (props) {
                component.updateProps(props)

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },
            onKeyDown (props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide()

                    return true
                }

                return component.ref?.onKeyDown(props)
            },
            onExit() {
                popup[0].destroy()
                component.destroy()
            }
        }
    }
}
