import { DateTime } from 'luxon'

export default {
    methods: {
        dateTime (date) {
            // console.log(`### datetime.js[${this.$.uid}]: Supplied date`)
            // console.dir(date)

            if (date) {
                if (date instanceof DateTime) {
                    date = date.toISO()
                }

                let dateObject = DateTime.fromISO(date)

                if (dateObject.invalid) {
                    let localeCheck = new Date()
                    localeCheck.setMonth(1)
                    localeCheck.setDate(10)
                    localeCheck = localeCheck.toLocaleDateString()

                    console.log(`### datetime.js[${this.$.uid}]: Locale check`)
                    console.dir(localeCheck)

                    if (/^2\/10\//.test(localeCheck)) {
                        // console.log('americanese')
                        dateObject = DateTime.fromFormat(date, 'MM/dd/yyyy')

                        if (dateObject.invalid) {
                            dateObject = DateTime.fromFormat(date, 'M/d/yyyy')
                        }

                        if (dateObject.invalid) {
                            dateObject = DateTime.fromFormat(date, 'MM/dd/yyyy HH:mm')
                        }

                        if (dateObject.invalid) {
                            dateObject = DateTime.fromFormat(date, 'MM/dd/yyyy H:mm')
                        }

                        if (dateObject.invalid) {
                            dateObject = DateTime.fromFormat(date, 'M/d/yyyy HH:mm')
                        }

                        if (dateObject.invalid) {
                            dateObject = DateTime.fromFormat(date, 'M/d/yyyy H:mm')
                        }
                    } else {
                        // console.log('not americanese')
                        dateObject = DateTime.fromFormat(date, 'dd/MM/yyyy')

                        if (dateObject.invalid) {
                            dateObject = DateTime.fromFormat(date, 'd/M/yyyy')
                        }

                        if (dateObject.invalid) {
                            dateObject = DateTime.fromFormat(date, 'dd/MM/yyyy HH:mm')
                        }

                        if (dateObject.invalid) {
                            dateObject = DateTime.fromFormat(date, 'dd/MM/yyyy H:mm')
                        }

                        if (dateObject.invalid) {
                            dateObject = DateTime.fromFormat(date, 'd/M/yyyy HH:mm')
                        }

                        if (dateObject.invalid) {
                            dateObject = DateTime.fromFormat(date, 'd/M/yyyy H:mm')
                        }
                    }
                }

                // console.log(`### datetime.js[${this.$.uid}]: Parsed date`)
                // console.dir(dateObject?.toFormat('D'))
                // console.dir(dateObject)
                // console.log(`### datetime.js[${this.$.uid}]: End`)
                // console.log('')

                return dateObject
            }

            // console.log(`### datetime.js[${this.$.uid}]: Creating new date`)
            // console.log(`### datetime.js[${this.$.uid}]: End`)
            // console.log('')

            return DateTime.now()
        }
    }
}
