<template>
        <input class="form--field--input" type="text" @input="onChange" :name="name" v-model="autoSearch" :placeholder="placeholder" :id="id" v-bind="props" @keyup.down="onArrowDown" @keyup.up="onArrowUp" @keyup.enter="onEnter" />
        <ul id="form--field__autocomplete-results" v-show="isOpen" class="form--field__autocomplete-results">
            <li class="loading" v-if="isLoading">
                Loading results...
            </li>
            <li v-else v-for="(result, i) in results" :key="i" @click="setResult(result.label)" class="form--field__autocomplete-result" :class="{ 'is_active': i === arrowCounter }">
                <span v-if="result">{{ result.label }}</span>
            </li>
        </ul>
</template>
<script>
export default {
    components: {},
    inheritAttrs: false,
    props: {
        name: String,
        modelValue: [String, Number, Array],
        label: String,
        placeholder: {
            type: String,
            required: false
        },
        id: String,
        props: {
            type: [Array,Object],
            required: false
        },
        isAsync: {
            type: Boolean,
            required: false,
            default: false
        },
        options: Object,
    },

    data() {
        return {
            isOpen: false,
            results: [],
            autoSearch: "",
            isLoading: false,
            arrowCounter: 0
        };
    },

    methods: {
        onChange() {
            // console.log(this.autoSearch)
            // Let's warn the parent that a change was made
            this.$emit("update:modelValue", this.autoSearch);

            // Is the data given by an outside ajax request?
            if (this.isAsync) {
                this.isLoading = true;
            } else {
                // Let's autoSearch our flat array
                this.filterResults();
                this.isOpen = true;
            }
        },

        filterResults() {
            // first uncapitalize all the things
                this.results = this.options.filter(item => {
                    if(item.label !== null) {
                        return item.label.toLowerCase().indexOf(this.autoSearch.toLowerCase()) > -1;
                    }
                });
        },
        setResult(result) {
            // console.log(result)
            this.$emit("update:modelValue", result);
            this.autoSearch = result;
            this.isOpen = false;
        },
        onArrowDown(evt) {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
            this.isOpen = true;
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
            this.isOpen = true;
        },
        onEnter(evt) {
            // this.autoSearch = this.results[this.arrowCounter];
            this.isOpen = false;
            this.arrowCounter = -1;
            this.autoSearch = '';
            this.$emit("update:modelValue", this.autoSearch);
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        }
    },
    watch: {
        options: function(val, oldValue) {
            // actually compare them
            if (val.length !== oldValue.length) {
                this.results = val;
                this.isLoading = false;
            }
        }
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
    destroyed() {
        document.removeEventListener("click", this.handleClickOutside);
    }
};

</script>
