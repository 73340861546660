<template>
    <div class="form--field--editor" :class="{ 'form--field--editor__no-label': !label, 'form--field--editor__simple': simple, 'form--field--editor__focussed': focussed }" v-bind="$attrs">
        <editor-content :editor="editor" @dblclick.prevent="doubleClickHandler"></editor-content>
    </div>

    <div v-if="!simple" class="form--field--toolbar">
        <div class="form--field--toolbar--select form--field form--field__select">
            <form-select type="select" :name="`${subId}--heading`" :id="`${subId}--heading`" :model-value="String(selectedHeading)" :options="headings" placeholder="Normal" @update:modelValue="changeHeading"></form-select>
        </div>

        <span class="form--field--toolbar--break"></span>

        <button class="form--field--toolbar--btn form--field--toolbar--btn__bold" :class="{ 'form--field--toolbar--btn__active': editor.isActive('bold') }" @click.prevent="editor.chain().focus().toggleBold().run()"></button>
        <button class="form--field--toolbar--btn form--field--toolbar--btn__italic" :class="{ 'form--field--toolbar--btn__active': editor.isActive('italic') }" @click.prevent="editor.chain().focus().toggleItalic().run()"></button>
        <button class="form--field--toolbar--btn form--field--toolbar--btn__underline" :class="{ 'form--field--toolbar--btn__active': editor.isActive('underline') }" @click.prevent="editor.chain().focus().toggleUnderline().run()"></button>

<!--        <span class="form&#45;&#45;field&#45;&#45;toolbar&#45;&#45;break"></span>-->

<!--        <div class="form&#45;&#45;field&#45;&#45;toolbar&#45;&#45;select form&#45;&#45;field form&#45;&#45;field__select">
            <form-select type="select" :name="`${subId}&#45;&#45;color`" :id="`${subId}&#45;&#45;color`" v-model="selectedColor" :options="colors" placeholder="Color" @update:modelValue="changeColor"></form-select>
        </div>

        <span class="form&#45;&#45;field&#45;&#45;toolbar&#45;&#45;break"></span>

        <div class="form&#45;&#45;field&#45;&#45;toolbar&#45;&#45;select form&#45;&#45;field form&#45;&#45;field__select">
            <form-select :name="`${subId}&#45;&#45;highlight`" :id="`${subId}&#45;&#45;highlight`" v-model="selectedHighlight" :options="highlights" placeholder="Highlight" @update:modelValue="changeHighlight"></form-select>
        </div>-->

        <span class="form--field--toolbar--break"></span>

        <button class="form--field--toolbar--btn form--field--toolbar--btn__bullet-list" :class="{ 'form--field--toolbar--btn__active': editor.isActive('bullet-list') }" @click.prevent="editor.chain().focus().toggleBulletList().run()"></button>
        <button class="form--field--toolbar--btn form--field--toolbar--btn__ordered-list" :class="{ 'form--field--toolbar--btn__active': editor.isActive('ordered-list') }" @click.prevent="editor.chain().focus().toggleOrderedList().run()"></button>

        <span class="form--field--toolbar--break"></span>

        <button class="form--field--toolbar--btn form--field--toolbar--btn__link" :class="{ 'form--field--toolbar--btn__active': editor.isActive('link') }" @click.prevent="editLink"></button>
<!--        <button class="form&#45;&#45;field&#45;&#45;toolbar&#45;&#45;btn form&#45;&#45;field&#45;&#45;toolbar&#45;&#45;btn__image" :class="{ 'form&#45;&#45;field&#45;&#45;toolbar&#45;&#45;btn__active': editor.isActive('image') }" @click.prevent="editImage"></button>-->
    </div>

        <template v-if="showHyperlink">
            <div class="form--field form--field__text">
                <form-text :name="`${subId}--link`" :id="`${subId}--link`" v-model="link"></form-text>
                <label class="form--field--label" :for="`${subId}--link`">Enter a URL</label>
            </div>
            <div>
            <button style="margin-right:1rem" class="btn btn__border" @click.prevent="updateLink"><slot name="cancel">{{ $t('buttons.cancel')}}</slot></button>
            <button class="btn" @click.prevent="updateLink"><slot name="cancel">{{ $t('buttons.update')}}</slot></button>
            </div>
        </template>


<!--    <dialogue ref="imageDialogue" @submit="updateImage">
        <template v-slot:default>
            <div class="form&#45;&#45;field form&#45;&#45;field__files">
                <form-files single accept="image/*" :name="`${subId}&#45;&#45;image`" :id="`${subId}&#45;&#45;image`" v-model="image"></form-files>
                <label class="form&#45;&#45;field&#45;&#45;label" :for="`${subId}&#45;&#45;image`">Select an image</label>
            </div>

            <div class="form&#45;&#45;field form&#45;&#45;field__select">
                <form-select :name="`${subId}&#45;&#45;image&#45;&#45;size`" :id="`${subId}&#45;&#45;image&#45;&#45;size`" v-model="imageSize" :options="imageSizes"></form-select>
                <label class="form&#45;&#45;field&#45;&#45;label" :for="`${subId}&#45;&#45;image&#45;&#45;size`">Size</label>
            </div>

            <div class="form&#45;&#45;field form&#45;&#45;field__select">
                <form-select :name="`${subId}&#45;&#45;image&#45;&#45;position`" :id="`${subId}&#45;&#45;image&#45;&#45;position`" v-model="imagePosition" :options="imagePositions"></form-select>
                <label class="form&#45;&#45;field&#45;&#45;label" :for="`${subId}&#45;&#45;image&#45;&#45;position`">Position</label>
            </div>
        </template>

        <template v-slot:submit>{{ editor.isActive('image') ? 'Update' : 'Insert' }} image</template>
    </dialogue>-->
</template>

<script>
import { Editor, EditorContent, mergeAttributes } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import TiptapImage from '@tiptap/extension-image'
import Mention from '@tiptap/extension-mention'
import MentionSuggestion from './rich-text/mention-suggestion'
import Dialogue from '../../dialogue'
import FormText from '../field/text'
import FormSelect from '../field/select'
import FormFiles from '../field/files'
import Form from '../../../src/form'

const Image = TiptapImage.extend({
    name: 'image',
    addOptions() {
        return {
            ...TiptapImage.options,
            size: false,
            position: false,
            HTMLAttributes: {},
            sizes: ['small', 'medium', 'large', 'full'],
            positions: ['left', 'center', 'right']
        }
    },
    addAttributes () {
        return {
            ...this.parent?.(),
            size: { rendered: false },
            position: { rendered: false }
        }
    },
    addCommands () {
        return {
            setImage: (options) => ({ tr, dispatch }) => {
                const { selection } = tr
                const node = this.type.create(options)

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }

                return true
            },
            setAttributes: (attributes) => ({ tr, dispatch }) => {
                if (attributes.hasOwnProperty('size') && !this.options.sizes.includes(attributes.size)) {
                    delete attributes.size
                }

                if (attributes.hasOwnProperty('position') && !this.options.positions.includes(attributes.position)) {
                    delete attributes.position
                }

                const { selection } = tr
                const options = { ...selection.node.attrs, ...attributes }
                const node = this.type.create(options)

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }
            }
        }
    },
    renderHTML ({ node, HTMLAttributes }) {
        HTMLAttributes.class = 'image'

        if (node.attrs.size) {
            HTMLAttributes.class += ` image__size-${node.attrs.size}`
        }

        if (node.attrs.position) {
            HTMLAttributes.class += ` image__position-${node.attrs.position}`
        }

        return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
    },
    parseHTML () {
        return [{
            tag: 'img',
            getAttrs: node => ({
                size: (node.className.match(/image__size-([^\s]+)/) || [undefined, undefined])[1],
                position: (node.className.match(/image__position-([^\s]+)/) || [undefined, undefined])[1]
            })
        }]
    }
})

export default {
    components: { EditorContent, Dialogue, FormText, FormSelect, FormFiles },
    props: {
        rows: {
            type: [String, Number],
            default: 3
        },
        name: String,
        modelValue: [String, Number],
        label: String,
        placeholder: {
            type: String,
            required: false
        },
        simple: {
            type: Boolean,
            default: false
        },
        id: String,
        props: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            subId: '',
            form: new Form(this),
            editor: null,
            value: '',
            focussed: false,
            headings: [
                { value: '2', label: 'Heading 1' },
                { value: '3', label: 'Heading 2' },
                { value: '4', label: 'Heading 3' },
                { value: 'undefined', label: 'Paragraph'},
            ],
            colors: [
                { value: 'none', label: 'Default' },
                { value: '#551B29', label: 'Maroon' },
                { value: '#aa2545', label: 'Red' },
                { value: '#f3c200', label: 'Yellow' },
                { value: '#8fa724', label: 'Green' },
                { value: '#00a2d3', label: 'Blue' },
            ],
            selectedHeading: undefined,
            selectedColor: undefined,
            selectedHighlight: undefined,
            link: '',
            image: undefined,
            imagePositions: [
                { value: 'undefined', label: 'Default' },
                { value: 'left', label: 'Left' },
                { value: 'center', label: 'Centre' },
                { value: 'right', label: 'Right' },
            ],
            imagePosition: undefined,
            imageSizes: [
                { value: 'undefined', label: 'Original' },
                { value: 'small', label: 'Small' },
                { value: 'medium', label: 'Medium' },
                { value: 'large', label: 'Large' },
                { value: 'full', label: 'Full' },
            ],
            imageSize: undefined,
            analysts: {},
            showHyperlink:false,
        }
    },
    computed: {
        theme () {
            return this.simple ? 'bubble' : 'snow'
        },
        toolbar () {
            if (this.simple) {
                return ['bold', 'italic', 'underline']
            }

            return [
                [{ 'header': [1, 2, 3, false] }],
                [{ list: 'ordered'}, { list: 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                ['bold', 'italic', 'underline'],
                ['clean']
            ]
        },
        highlights () {
            return JSON.parse(JSON.stringify(this.colors)).map(color => {
                if (color.value === 'none') {
                    color.label = 'None'
                } else {
                    color.value = `${color.value}33`
                }

                return color
            })
        }
    },
    watch: {
        modelValue (value) {
            if (this.value !== value) {
                this.value = value
                this.editor.commands.setContent(value, false)
            }
        },
        value (value) {
            this.$emit('update:modelValue', value)
        }
    },
    methods: {
        focus () {
            this.focussed = true
        },
        blur () {
            this.focussed = false
        },
        updateSelection () {
            this.selectedHeading = [...Array(6).keys()].find(i => {
                return this.editor.isActive('heading', { level: i })
            })

            // this.selectedColor = (this.colors.find(color => {
            //     return this.editor.isActive('color', color.value)
            // }) || { value: undefined }).value

            // this.selectedHighlight = (this.highlights.find(highlight => {
            //     return this.editor.isActive('highlight', { color: highlight.value })
            // }) || { value: undefined }).value
        },
        changeHeading (value) {
            if (value === 'undefined') {
                this.editor.chain().focus().toggleHeading({ level: this.selectedHeading }).run()
            } else {
                this.editor.chain().focus().setHeading({ level: Number(value) }).run()
            }
        },
        changeColor (value) {
            if (String(value) === 'none') {
                this.editor.chain().focus().unsetColor().run()
            } else if (String(value) !== 'undefined') {
                this.editor.chain().focus().setColor(value).run()
                this.$nextTick(() => this.selectedColor = undefined)
            }
        },
        changeHighlight (value) {
            if (String(value) === 'none') {
                this.editor.chain().focus().unsetHighlight().run()
            } else if (String(value) !== 'undefined') {
                this.editor.chain().focus().setHighlight({ color: value }).run()
                this.$nextTick(() => this.selectedHighlight = undefined)
            }
        },
        editLink () {
            if (this.editor.isActive('link')) {
                this.link = this.editor.getAttributes('link').href
            } else {
                this.link = ''
            }

            this.showHyperlink = true;
        },
        updateLink () {
            console.log(this.link)
            this.editor.chain().focus().extendMarkRange('link').setLink({ href: this.link }).run()

            this.showHyperlink = false;
            this.link = ''
        },
        updateImage () {
            if (this.image) {
                this.editor.chain().focus().setImage({
                    src: this.image[0].url,
                    size: this.imageSize,
                    position: this.imagePosition
                }).run()
            }

            this.$refs.imageDialogue.hide()
            this.image = []
            this.imageSize = undefined
            this.imagePosition = undefined
        },
        editImage () {
            if (this.editor.isActive('image')) {
                const attributes = this.editor.getAttributes('image')
                this.image = [{ url: attributes.src, name: attributes.src.split('/').reverse()[0] }]
                this.imageSize = attributes.size
                this.imagePosition = attributes.position
            } else {
                this.image = []
                this.imageSize = undefined
                this.imagePosition = undefined
            }

            this.$refs.imageDialogue.show()
        },
        doubleClickHandler (e) {
            if (this.editor.isActive('link')) {
                this.editLink()
            } else if (this.editor.isActive('image')) {
                this.editImage()
            }
        }
    },
    created () {
        let subId = `rich-text_${Math.round(Math.random() * 10000)}`

        while (document.getElementById(subId) instanceof Element) {
            subId = `rich-text_${Math.round(Math.random() * 10000)}`
        }

        this.subId = subId

        this.value = this.modelValue || ''

        this.editor = new Editor({
            content: this.value,
            autofocus: false,
            extensions: [
                StarterKit,
                TextStyle,
                Color,
                Highlight.configure({ multicolor: true }),
                Underline,
                Link.configure({ openOnClick: false, HTMLAttributes: { target: '_blank' } }),
                Image,
                Mention.configure({ suggestion: MentionSuggestion, HTMLAttributes: { class: 'mention' } }),
            ],
            onUpdate: () => {
                this.value = this.editor.getHTML()
                this.updateSelection()
            },
            onSelectionUpdate: this.updateSelection,
            onFocus: () => {
                this.focussed = true
            },
            onBlur: () => {
                this.focussed = false
            }
        })
    },
    beforeUnmount () {
        this.editor.destroy()
    }
}
</script>
