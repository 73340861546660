<template>
    <section class="main-body--sidebar" :class="{'main-body--sidebar--hidden': !user}">
        <div class="main-body--sidebar--logo">
        </div>
        <div class="main-body--sidebar--menu-content" :class="{'main-body--sidebar--menu-content--collapsed': !expanded}">
        <div class="main-body--sidebar--menu-content--main-box" v-for="(route,index) in routes" :key="index">
            <MenuItem :data="route" :idx="index"/>
        </div>
        </div>
        <div class="main-body--sidebar--footer">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
<!--                    07795 104989-->
                    <span v-if="!isMobile" class="main-body--sidebar--footer-icon">{{ userInitials }}</span>
                    <span v-if="!isMobile"><router-link :to="{ name: 'profile.dashboard' }">{{ $t('menu.my_profile')}}</router-link></span>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div style="float:right" v-if="user">
                        <router-link :to="{ name: 'auth.logout' }">
                            <span v-if="!isMobile">{{ $t('menu.logout')}}</span> <i class="icon icon__logout"></i> </router-link>
                    </div>
                </div>
            </div>
        </div>
        <button v-on:click="menuToggle">
            <i class="icon icon__chevron-left" :class="{'icon icon__chevron-right': !expanded}"></i>
        </button>
    </section>
</template>

<script>
import MenuItem from "./MenuItem.vue";

export default {
    components: {
        MenuItem
    },data () {
        return {
            user: null,
            userInitials:null,
            expanded:true,
            isMobile: false,
        }
    },
    computed: {
        routes() {
            return this.$router.options.routes;
        }
    },
    watch: {
        '$route' (to, from) {
            if(this.isMobile) {
                console.log(to, from);
                if (from.name !== undefined && to.name !== 'manage.enquiry') {
                    this.menuToggle()
                }
            }
        }
    },
    methods: {
        getInitials(fullName) {
            const allNames = fullName.trim().split(' ');
            const initials = allNames.reduce((acc, curr, index) => {
                if(index === 0 || index === allNames.length - 1){
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
            return initials;
        },
        menuToggle() {
            this.expanded = !this.expanded;
            const sidebar = document.querySelector('.main-body--sidebar'),
            sidebarLogo = document.querySelector('.main-body--sidebar--logo'),
            mainContent = document.querySelector('.main-body--main-content');

                sidebar.classList.toggle('main-body--sidebar-small');
                sidebarLogo.classList.toggle('main-body--sidebar--logo-small');
                mainContent.classList.toggle('main-body--main-content-large');
        },
        checkMobile () {
            this.isMobile = screen.width <= 1000
        },
    },
    mounted () {
        if (localStorage.getItem('user') !== undefined) {
            this.user = JSON.parse(localStorage.getItem('user'))
            this.userInitials = this.getInitials(this.user?.first_name+' '+this.user?.last_name);
            this.checkMobile();
        }
    }
};
</script>
