<template>
    <input class="form--field--input" :type="type" :inputmode="inputmode" :name="name" :placeholder="placeholder" :id="id" ref="input" v-model="value" @input="input">
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        type: {
            type: String,
            required: false
        },
        inputmode: {
            type: String,
            required: false
        },
        name: String,
        modelValue: [String, Number],
        label: String,
        placeholder: {
            type: String,
            required: false
        },
        id: String,
        props: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            value: ''
        }
    },
    watch: {
        modelValue (value) {
            this.$nextTick().then(() => {
                if (value !== this.value) {
                    if (typeof value === 'undefined') {
                        this.value = ''
                    } else {
                        this.value = value
                    }
                }
            })
        }
    },
    methods: {
        input (e) {
            if (this.modelValue !== e.target.value) {
                this.$emit('update:modelValue', e.target.value)
            }
        }
    },
    mounted () {
        if (typeof this.modelValue !== 'undefined') this.value = this.modelValue
    }
}
</script>
