<template>
    <input class="form--field--input" :class="{ 'form--field--input__checked': checked }" type="checkbox" :name="name" :value="$attrs.value" v-model="value" :id="id" v-bind="props" ref="input">
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        name: String,
        inputValue: [String, Number, Boolean],
        modelValue: [String, Number, Boolean],
        id: String,
        props: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            value: ''
        }
    },
    computed: {
        checked () {
            return this.value == this.$attrs.value
        }
    },
    watch: {
        modelValue (value) {
            this.value = value
        },
        value (value) {
            this.$emit('update:modelValue', value)

            if (this.$parent.$parent.$data.hasOwnProperty('errors') && this.$parent.$parent.errors.hasOwnProperty(this.name)) {
                delete this.$parent.$parent.errors[this.name]
            }
        }
    },
    created () {
        if (typeof this.value !== 'undefined') this.value = this.modelValue
    }
}
</script>
