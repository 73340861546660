import Vue from 'vue'
import Form from './src/form'

const form = new Form()

export default {
    logout () {
        let user = JSON.parse(localStorage.getItem('user'));
        form.get('/portal/logout/'+ user['guid']).then(response => {
            location.href='/login'
        })
        localStorage.clear()
    },

    getCookie (cname) {
        let name = cname + '='
        let ca = document.cookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return 'Invalid Date'
    }
}
