<template>
    <div class="main-body--sidebar--menu-content--main-box-ul" v-if="displayRoute && (accessGranted.length > 0 && permissionAccess)">
        <template v-if="data.meta.breadcrumb">
            <router-link exact-active-class="main-body--sidebar--menu-content--main-box-li-active"
                         :to="{name: data.name}" class="main-body--sidebar--menu-content--main-box-li">
                <div class="main-body--sidebar--menu-content--main-box-li-text"
                     :class="{'main-body--sidebar--menu-content--main-box-li-text_heading': data.meta.header === true}"
                     v-on:click="expandMenu(data.meta.label)">
                    {{ $t(data.meta.label) }}
                    <i class=""
                       v-if="data.meta.header === true && expand && data.children && data.children.length > 0 && data.children.display === true">-</i>
                    <i class=""
                       v-if="data.meta.header === true && !expand && data.children && data.children.length > 0 && data.children.display === true">+</i>
                </div>
                <div class="main-body--sidebar--menu-content--main-box-li-accordion"
                     v-if="expand && menuItem === data.meta.label">
                    <MenuItem v-for="(child,index) in data.children" :key="index" :data="child"/>
                </div>
            </router-link>
        </template>
        <template v-else>
            <section class="main-body--sidebar--menu-content--main-box-li-active">
                <div class="main-body--sidebar--menu-content--main-box-li-text"
                     :class="{'main-body--sidebar--menu-content--main-box-li-text_heading': data.meta.header === true}"
                     v-on:click="expandMenu(data.meta.label)">
                    {{ $t(data.meta.label) }}
                    <i class=""
                       v-if="data.meta.header === true && expand && data.children && data.children.length > 0">-</i>
                    <i class=""
                       v-if="data.meta.header === true && !expand && data.children && data.children.length > 0">+</i>
                </div>
                <div class="main-body--sidebar--menu-content--main-box-li-accordion"
                     v-if="expand && menuItem === data.meta.label">
                    <MenuItem v-for="(child,index) in data.children" :key="index" :data="child"/>
                </div>
            </section>
        </template>
    </div>
</template>

<script>
export default {
    computed: {
        /**
         * check if the route can be displayed or not
         * @return {Boolean}
         */
        displayRoute () {
            if (this.isMobile) {
                return this.data.meta.mobile
            } else {
                return this.data.meta.display === undefined || this.data.meta.display
            }
        },
        permissionAccess () {
            if (this.data.meta.permissions !== undefined) {
                return this.can(this.data.meta.permissions)
            }
        }
    },
    name: 'MenuItem',
    props: {
        data: Object,
        idx: Number
    },
    data () {
        return {
            expand: false,
            menuItem: '',
            accessGranted: {},
            user: [],
            isDisabled: false,
            permissions: {},
            isMobile: false
        }
    },
    methods: {
        checkMobile () {
            this.isMobile = screen.width <= 1000
        },
        expandMenu (name) {
            this.menuItem = name
            this.expand = !this.expand
        },
        userAllowed: function () {
            if (this.data.meta.roles !== undefined) {
                const roles = this.data.meta.roles.split(',')
                this.accessGranted = roles.filter(role => {
                    if (this.user?.role.name === role) {
                        return true
                    }
                })
            }
        }
    },
    created () {
    },
    mounted () {
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'))
            this.userAllowed()
            if (this.user.role.name === 'Activities Provider') {
                this.expandMenu('menu.activity_provider')
            }
        }
        this.checkMobile()
    }
}
</script>
