const userPermissions = []
if (localStorage.getItem('user')) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.permissions.length > 0) {
        if (user.role.name === 'Activities Provider') {
            user.permissions.push({ type: 'Manage provider', active: 1 })
        }
        user.permissions.forEach(permission => {
            if (permission.active === 1) {
                userPermissions.push(permission.type)
            }
        })
    }
}
export default
{
    methods: {
        can: (key) => userPermissions.includes(key)
    }
}
