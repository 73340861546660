<template>
    <textarea class="form--field--input" style="padding-top:0.5rem" :rows="rows" :name="name" :placeholder="placeholder" :id="id" ref="input" @input="input"></textarea>
    <span class="form--field--input-fix">{{ $refs.input?.value }}</span>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        rows: {
            type: [String, Number],
            required: false
        },
        name: String,
        modelValue: [String, Number],
        label: String,
        placeholder: {
            type: String,
            required: false
        },
        id: String,
        props: {
            type: Object,
            required: false
        }
    },
    watch: {
        modelValue (value) {
            if (this.modelValue !== this.$refs.input.value) {
                if (typeof this.modelValue === 'undefined') {
                    this.$refs.input.value = ''
                } else {
                    this.$refs.input.value = this.modelValue
                }
            }
        }
    },
    methods: {
        input (e) {
            if (this.modelValue !== e.target.value) {
                this.$emit('update:modelValue', e.target.value)
            }
        }
    },
    mounted () {
        if (typeof this.modelValue !== 'undefined') this.$refs.input.value = this.modelValue
    }
}
</script>

