<template>
    <div v-if="user" class="main-body--breadcrumb-header--breadcrumbs">
        <span v-if="user.role?.name === 'Activities Provider'" class="h4" :class="{'h3': routes.length === 0}"><img :src="user.brand?.thumb" :alt="user.brand?.name">{{ user.provider.name }}</span>
        <span v-if="user.role?.name === 'Activities Provider' && routes.length > 0"> - </span>
        <span v-for="(matched, idx) in routes" :key="idx">
            <a v-if="matched.meta.breadcrumb" class="h4" :class="{'h3': idx !== Object.keys(this.$route.matched).length -1 || (user.role?.name === 'Activities Provider' && routes.length > 0) }" v-on:click="goTo(matched.url)">
                <span v-if="matched.meta.permissions !== 'Manage reports'">{{ $t('menu.manage')}} {{ $t(matched.name) }} </span>
                <span v-else>{{ $t('general.company_name')}}</span>
            </a>
            <span v-if="idx > 0 && idx !== Object.keys(this.$route.matched).length - 1"> - </span>
        </span>
<!--        <Notifications></Notifications>-->
    </div>
</template>
<script>

import router from "../../router";
import Notifications from "../admin/notifications/notifications.vue";
//v-if="routes.length === 1 || (routes.length > 2 && idx !== 0)"
export default {
    components: {Notifications},
    data() {
        return {
            user: null,
            breadcrumbs : []
        }
    },
    computed: {
        routes() {
            const newRoutes = [];
            let url = '';
            for(let i = 0; i < this.$route.matched.length; i++){
                if(this.$route.params.guid){
                    url = this.$route.matched[i].path.replace(":guid",this.$route.params.guid);
                } else {
                    url = this.$route.matched[i].path;
                }
                newRoutes.push({'name':this.$route.matched[i].meta.label,'url':url , 'meta': this.$route.matched[i].meta})
            }
            return newRoutes;
        }
    },
    watch: {},
    methods: {
        goTo(url) {
            router.replace({path:url});
        }
    },
    created() {
    },
    mounted() {
        if (localStorage.getItem('user') !== undefined) {
            this.user = JSON.parse(localStorage.getItem('user'))
        }
    }
}
</script>
