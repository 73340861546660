<template>
    <div class="dialogue" :class="{ 'dialogue__visible': visible }">
        <div class="dialogue--container">
            <a href="#" class="dialogue--container--close icon__close" @click.prevent="cancel"></a>

            <div class="dialogue--container--content">
                <slot></slot>
            </div>

            <div class="dialogue--container--buttons">
                <button class="dialogue--container--buttons--button__cancel btn btn__tertiary" @click.prevent="cancel"><slot name="cancel">Cancel</slot></button>
                <slot name="buttons"></slot>
                <button class="dialogue--container--buttons--button__submit btn btn__primary" @click.prevent="submit"><slot name="submit">Submit</slot></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        disableKeyEvents: Boolean
    },
    data () {
        return {
            visible: false
        }
    },
    methods: {
        show () {
            this.visible = true
            return new Promise(resolve => resolve())
        },
        hide () {
            this.visible = false
            return new Promise(resolve => setTimeout(() => resolve(), 250))
        },
        cancel () {
            this.$emit('cancel')
            this.hide()
        },
        submit (e) {
            if (!this.visible || (e instanceof KeyboardEvent && e.key !== 'Enter')) return
            console.log(e)
            this.$emit('submit', e)
        }
    },
    mounted () {
        if (!this.disableKeyEvents) {
            window.addEventListener('keyup', this.submit)
        }
    },
    beforeUnmount () {
        if (!this.disableKeyEvents) {
            window.removeEventListener('keyup', this.submit)
        }
    }
}
</script>
